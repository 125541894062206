/* Base styles */
.gallery-container h2 {
  text-align: center;
  color: black;
  margin-top: 30px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 50px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px;
}

.gallery-item {
  flex: 0 0 30%;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 25px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
}

.food {
  border: 3px solid #ff6347;
}

.interior {
  border: 3px solid #4682b4;
}

.past {
  border: 3px solid #1d6030;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  max-height: 80%;
  margin-top: 70px;
}

.modal.open {
  display: block;
}

/* Responsive styles */

/* Extra small devices (phones, less than 600px) */
@media (max-width: 599px) {
  .gallery-container h2 {
    font-size: 30px;
  }

  .gallery-grid {
    padding: 20px;
  }

  .gallery-item {
    flex: 0 0 100%;
    margin-top: 15px;
  }

  .modal-content {
    width: 90%;
    max-width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  .gallery-container h2 {
    font-size: 35px;
  }

  .gallery-grid {
    padding: 30px;
  }

  .gallery-item {
    flex: 0 0 45%;
    margin-top: 20px;
  }

  .modal-content {
    width: 85%;
    max-width: 600px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .gallery-container h2 {
    font-size: 40px;
  }

  .gallery-grid {
    padding: 40px;
  }

  .gallery-item {
    flex: 0 0 45%;
    margin-top: 20px;
  }

  .modal-content {
    width: 80%;
    max-width: 650px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .gallery-container h2 {
    font-size: 50px;
  }

  .gallery-grid {
    padding: 50px;
  }

  .gallery-item {
    flex: 0 0 30%;
    margin-top: 25px;
  }

  .modal-content {
    width: 80%;
    max-width: 700px;
  }
}