  .bodycontainerhome {
    margin-top: 50px;
    text-align: left;
    margin-left: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  
  .homebodyabout {
    background-color:rgba(7,1,65,255);
    color: #fff;
    padding: 20px;
    margin-right: 20px;
    border-radius: 25px;
    border-bottom-width: 50%;
    border-bottom: 5px solid rgb(151, 141, 141);
    border-right: 5px solid rgb(151, 141, 141);
  }
  
  .imgwithpara {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .imgwithpara img {
    height: 300px;
    width: 100%;
    max-width: 500px;
    margin: 20px 0;
    border-radius: 15px;
  }
  
  .homebodypara {
    flex: 1;
    font-size: 22px;
  }

  .imageset {
    margin-top: 80px;
    display: flex;
    overflow: hidden;
    height: 100px;
    margin-bottom: 80px;
  }
  
  .imageset-inner {
    display: flex;
    animation: moveImages 10s linear infinite;
  }
  
  .imageset img {
    height: 100px; 
    margin: 0 10px;
    border: 1px solid gray;
  }



  /*jobcard*/
  .card-title-custom {
    color: rgb(39, 105, 199);
  }

  .card-custom {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the shadow properties as needed */
  }
  
  .card-custom:hover {
    box-shadow: 0px 8px 16px rgba(4, 29, 113, 0.841); /* Adjust hover shadow properties if desired */
  }
  

  /*footer*/
  /* src/components/Footer.css
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 100px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-left, .footer-right {
  margin: 10px 0;
}

.footer-left h2 {
  margin-bottom: 10px;
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ddd;
}

.footer-right p {
  margin: 5px 0;
}

@media (min-width: 600px) {
  .footer-content {
    flex-direction: row;
  }

  .footer-left, .footer-right {
    flex: 1;
    text-align: left;
  }

  .footer-left {
    text-align: left;
  }

  .footer-right {
    text-align: right;
  }
} */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 100px;
}

.footer-left, .footer-right {
  margin: 10px 0;
}

.footer-left h2 {
  margin-bottom: 10px;
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ddd;
}

.footer-right p {
  margin: 5px 0;
}

.footer-bottom {
  margin-top: 20px;
}

.footer a {
  text-decoration: none;
  color: white;
}

/* Company card */
.card-custom-company {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.card-custom-company:hover {
  box-shadow: 0px 8px 16px rgba(4, 29, 113, 0.841);
}

.card-image-container {
  width: 100%;
  height: 300px; /* Set the desired height */
  overflow: hidden;
}

.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-hover-content-company {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  text-align: center;
  height: 0;
  overflow: hidden;
  transition: height 1s ease-in;
}

.card-custom-company:hover .card-hover-content-company {
  height: 75%; /* Cover 3/4 of the card height */
}

.card-custom-company .card-title {
  color: rgb(250, 250, 250);
  margin-top: 20px; /* Initial margin for title */
}

.card-custom-company .card-text {
  display: none;
}

.card-custom-company:hover .card-title {
  margin-top: 20px; /* Ensure margin remains consistent on hover */
}

.card-custom-company:hover .card-text {
  display: block;
  margin-top: 20px; /* Adjust margin to fit within the expanded card */
  color: #fff;
}

/*vacancy page*/
.applybtn{
  padding: 10px;
  margin-bottom: 20px;
}

/* Input search styles */
input[type="search"] {
  width: 400px;
  border: 1px solid gray;
  padding: 20px;
  border-radius: 30px;
  position: relative;
  margin-left: 400px;
  margin-top: 20px;
  z-index: 1;
  height: 20px;
}

.categoryjob{
  background-color: rgb(7, 23, 56);
  padding: 10px 0;
  color: white;
  /*border-radius: 10px;*/
}

.vccardimg{
  width: 400px;
  height: 300px;
}

.vacancycard{
  margin-top: 30px;
}


/*full job details*/
.fulljobdetails{
  width: 70%;
  /* align-items: center; */
  margin-top: 50px;
}

.fulljobbody{
  text-align: left;
}




@media (min-width: 600px) {
  .footer-content {
    flex-direction: row;
  }

  .footer-left, .footer-right {
    flex: 1;
    text-align: left;
  }

  .footer-left {
    text-align: left;
  }

  .footer-right {
    text-align: right;
  }
}
  
  
  @keyframes moveImages {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes move {
    0% { transform: translateX(0); }
    50% { transform: translateX(25px); }
    100% { transform: translateX(0); }
  }
  
  .homebodyabout h1 b {
    display: inline-block;
    animation: move 2s infinite;
    color: red;
  }
  
  @media (max-width: 600px) {
    .homevideo video {
      width: 50%;
      margin: 10px 0;
    }
  
    .homevideo {
      margin-left: -280px;
      text-align: center;
    }
  
    .navbar-collapse .nav-link {
      font-size: 20px;
      height: 10px;
      gap: 5px;
      text-align: left;
    }
  
    .navbar-collapse .navbar-nav {
      gap: 5px;
      margin-left: -10px;
    }
  
    .navbar-toggled {
      margin-bottom: 180px;
    }
  
    .imgwithpara {
      flex-direction: column;
      align-items: center;
    }
  
    .imgbg img {
      width: 100%;
      height: auto;
      margin-top: 10px;
    }
  
    .homebodypara {
      margin-top: 20px;
      margin-right: 20px;
      text-align: center;
    }
  
    .homebodyabout h1 {
      padding-top: 20px;
      text-align: center;
    }

    .footer-content {
      /*flex-direction: row;*/
      padding: 0;
    }

    .vacancycard{
      margin: 20px 15px 0 15px;
    }

    .vccardimg{
      width: 200px;
      height: 200px;
    }
    input[type="search"] {
      width: 80%;
      margin-left: 50px;
      padding: 10px;
      height: auto;
      border-radius: 15px;
    }
    .loginpageform{
      margin-top: 200px;
    }
  
  }

  /* Contact section */
.contact {
  text-align: center;
}

.contact h2 {
  font-size: 40px;
  color: #4d4d4d;
}

.contact h1 {
  font-size: 50px;
  color: rgb(162, 137, 94);
}

.contact .border {
  border-bottom: 2px solid #5f5f5f;
  margin: 20px auto;
  width: 50%;
}

.contact-form {
  max-width: 900px;
  margin: 0 auto;
  background-color: #202020;
  padding: 80px 100px;
  border-radius: 20px;
}

.contact-form .form-label {
  font-size: 18px;
  color: white;
}

.contact-form .form-control {
  border-radius: 15px;
  margin-bottom: 15px;
}

.contact-form .submit-button {
  width: 200px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  margin: 20px auto 0;
}

.contact-form .submit-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */

/* Extra small devices (phones, less than 600px) */
@media (max-width: 599px) {
  .contact h2 {
    font-size: 24px;
  }

  .contact h1 {
    font-size: 32px;
  }

  .contact .border {
    width: 80%;
  }

  .contact-form {
    padding: 40px 20px;
  }

  .contact-form .submit-button {
    margin: 20px auto 0;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  .contact h2 {
    font-size: 28px;
  }

  .contact h1 {
    font-size: 36px;
  }

  .contact .border {
    width: 70%;
  }

  .contact-form {
    padding: 60px 30px;
  }

  .contact-form .submit-button {
    margin: 20px auto 0;
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .contact h2 {
    font-size: 32px;
  }

  .contact h1 {
    font-size: 40px;
  }

  .contact .border {
    width: 60%;
  }

  .contact-form {
    padding: 70px 50px;
  }

  .contact-form .submit-button {
    margin: 20px auto 0;
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .contact h2 {
    font-size: 40px;
  }

  .contact h1 {
    font-size: 50px;
  }

  .contact .border {
    width: 50%;
  }

  .contact-form {
    padding: 80px 100px;
  }

  .contact-form .submit-button {
    width: 200px;
    margin-left: 0;
  }
}